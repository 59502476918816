<template>
  <input
    :class="['input w-full', {'p-filled': filled}]"
    :value="modelValue"
    @input="onInput"
  >
</template>

<script>
export default {
    props: {
        modelValue: null
    },
    emits: ['update:modelValue'],
    computed: {
        filled() {
            return (this.modelValue != null && this.modelValue.toString().length > 0)
        }
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>